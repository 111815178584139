<script setup lang="ts">
import type { ContentQueryItem } from '~/types'
import { useConfigStore } from '~/store'

const props = defineProps<{ queryItem: ContentQueryItem, showAd?: boolean }>()
const config = useConfigStore()
const listContentByPage = listContentByPageWrapper(props.queryItem.type)
const { results } = await listContentByPage(1, 10)
</script>

<template>
  <CarouselBase :to="props.queryItem.href">
    <template #title>
      {{ props.queryItem.label }}
    </template>

    <section v-for="(i, index) of results || []" :key="i.id" class="flex-1 w-60 lg:w-[300px]">
      <ContentPlacement v-if="config.showAds && showAd && index === 4" placement-id="mrec_homepage" />

      <ContentCard v-else :item="i">
        <template #title>
          {{ i.title }}
        </template>
        <template #metaInfo>
          <label v-if="i.duration">Play Time: <span class="text-green-400">{{ i.duration }}</span></label>
        </template>
        <template #description>
          {{ i.description }}
        </template>
      </ContentCard>
    </section>
  </CarouselBase>
</template>
