<script setup lang="ts">
import type { Content } from '~/types'

const props = withDefaults(defineProps<{
  list: Content[]
}>(), {
  list: () => ([]),
})
</script>

<template>
  <Swiper
    :modules="[SwiperAutoplay, SwiperPagination]"
    :loop="true"
    :speed="1500"
    :pagination="{
      clickable: true,
      bulletClass: 'bg-gray-400/20 w-2.5 h-2.5 inline-block rounded-full cursor-pointer mx-1.5',
      bulletActiveClass: 'bg-white/100',
    }"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
  >
    <SwiperSlide v-for="item in props.list" :key="item.id">
      <HomeSlide :item="item" />
    </SwiperSlide>
  </Swiper>
</template>
