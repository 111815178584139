<script setup lang="ts">
const props = defineProps<{ to: string }>()

const scrollEl = ref<HTMLDivElement>()

function scrollLeft() {
  scrollEl.value?.scrollBy({
    left: -window.innerWidth,
    behavior: 'smooth',
  })
}

function scrollRight() {
  scrollEl.value?.scrollBy({
    left: window.innerWidth,
    behavior: 'smooth',
  })
}
</script>

<template>
  <NuxtLink :to="props.to" class="flex px-10 pt-3 mt-5 text-2xl w-max group">
    <slot name="title" />
    <Icon name="i-material-symbols-chevron-right-rounded" size="2rem" class="transition duration-500 group-hover:translate-x-2" />
  </NuxtLink>
  <div class="relative">
    <div ref="scrollEl" class="overflow-y-auto no-scrollbar">
      <div class="flex gap-2 p-2 px-10 w-max">
        <slot />
      </div>
    </div>
    <button
      class="absolute top-0 bottom-0 left-0 flex flex-col items-center justify-center p-3 transition opacity-0 bg-black/50 hover:opacity-100"
      title="Scroll left"
      @click="scrollLeft()"
    >
      <Icon name="i-ph-caret-left-light" class="text-3xl text-white" />
    </button>
    <button
      class="absolute top-0 bottom-0 right-0 flex flex-col items-center justify-center p-3 transition opacity-0 bg-black/50 hover:opacity-100"
      title="Scroll right"
      @click="scrollRight()"
    >
      <Icon name="i-ph-caret-right-light" class="text-3xl text-white" />
    </button>
  </div>
</template>
