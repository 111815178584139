<script setup lang="ts">
import type { Content } from '~/types'

const props = withDefaults(defineProps<{
  item: Content
}>(), {
  item: () => ({} as Content),
})

const mounted = useMounted()

const path = `/watch/${props.item.type}/${props.item.fetchId}`

function handleClick(e: MouseEvent) {
  e.preventDefault()
  navigateTo(path)
}
</script>

<template>
  <div :key="item.id" class="relative aspect-[4/3] lg:aspect-[25/8] bg-black">
    <div class="absolute top-0 left-0 right-0 lg:bottom-0 lg:left-1/3">
      <NuxtImg
        format="webp"
        :src="props.item.thumbnail"
        :alt="props.item.title"
        class="object-cover w-full h-full"
      />
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center p-10 from-black via-black to-transparent bg-gradient-to-t lg:px-24 lg:w-2/3 lg:bg-gradient-to-r"
    >
      <Transition appear name="hero">
        <div v-show="mounted">
          <h1 class="mt-2 text-xl sm:text-3xl lg:text-5xl line-clamp-2 lg:leading-tight">
            {{ props.item.title }}
          </h1>
          <p class="mt-2 overflow-hidden text-xs leading-relaxed opacity-80 line-clamp-2 md:line-clamp-3 md:text-base">
            {{ props.item.description }}
          </p>
          <div class="hidden py-5 lg:block">
            <NuxtLink
              :href="path" :title="item.title" class="flex items-center gap-2 px-6 py-3 transition w-fit bg-gray-400/20 hover:bg-gray-400/25"
              @click="handleClick"
            >
              <Icon name="i-ph-play" />
              Watch Now
            </NuxtLink>
          </div>
        </div>
      </Transition>
    </div>
    <div class="absolute top-0 left-0 right-0 lg:hidden h-2/3 ">
      <NuxtLink
        :href="path" :title="item.title" class="p-10 text-5xl transition opacity-20 hover:opacity-80"
        @click="handleClick"
      >
        <Icon name="i-ph-play-circle-light" class="w-12 h-12 md:w-20 md:h-20" />
      </NuxtLink>
    </div>
  </div>
</template>

<style>
.hero-enter-active,
.hero-leave-active {
  transition: transform .75s cubic-bezier(.4, .25, .3, 1), opacity .3s cubic-bezier(.4, .25, .3, 1);
}

.hero-enter-from,
.hero-leave-to {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
}

.hero-enter-to,
.hero-leave-from {
  opacity: 1;
  transform: translateZ(0);
}
</style>
